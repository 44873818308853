import React from 'react'
import styled from '@emotion/styled'
import Thumbnail, { ThumbnailSrc } from 'shared/library/atoms/Thumbnail'

interface ItemTextProps {
  /**
   * Takes a string. corresponds to the background.
   */
  thumbnailSrc?: ThumbnailSrc
}

const ItemText: React.FC<ItemTextProps> = ({ children, thumbnailSrc }) => {
  return (
    <StyledContainer thumbnailSrc={thumbnailSrc}>
      {thumbnailSrc ? (
        <>
          <StyleThumbnail src={thumbnailSrc} />
          <StyledWrapper>{children}</StyledWrapper>
        </>
      ) : (
        children
      )}
    </StyledContainer>
  )
}

export default ItemText

const StyledContainer = styled.div<ItemTextProps>(
  ({ theme, thumbnailSrc }) => ({
    display: 'flex',
    flexDirection: thumbnailSrc ? 'row' : 'column',
    flexWrap: thumbnailSrc ? undefined : 'wrap',
    padding: `0 ${theme.space[4]}px ${theme.space[4]}px`,
    width: '100%',
    '& > *': {
      width: '100%'
    },
    '& > p, & > div > p': {
      marginTop: theme.space[3],
      marginBottom: 0
    }
  })
)

const StyledWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 0',
  '& > p': {
    marginTop: theme.space[1],
    marginBottom: 0
  }
}))

const StyleThumbnail = styled(Thumbnail)(({ theme }) => ({
  marginRight: theme.space[2]
}))
