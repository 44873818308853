import React from 'react'
import styled from '@emotion/styled'
import Image from 'next/legacy/image'
import {
  DEFAULT_FALLBACK_IMAGE_NAME,
  DEFAULT_FALLBACK_IMAGE_THUMBNAIL
} from 'enums/image'
import useTranslation from 'next-translate/useTranslation'
import { generateCloudinaryUrl } from 'shared/helpers/generateCloudinaryUrl'

export type ThumbnailSrc = string

interface ThumbnailProps {
  /**
   * Takes a string. corresponds to the background.
   */
  src?: ThumbnailSrc
  /**
   * Takes a string to output a class. Related to emotion - It lets another component style it
   */
  className?: string
  thumbnailSize?: number
  alt?: string
}

/**
 * Combine the <div> wrapper and <img/> to create the border effect because the border applied
 * straight at the image didn't look like the design.
 */
const WRAPPER_SIZE = 80

const Thumbnail = ({
  src = DEFAULT_FALLBACK_IMAGE_THUMBNAIL,
  className,
  thumbnailSize = WRAPPER_SIZE,
  alt
}: ThumbnailProps) => {
  const { t } = useTranslation('common')

  const imageSrc = src.startsWith('image/upload')
    ? generateCloudinaryUrl(src, 'thumb')
    : src

  const imageSize = thumbnailSize - 4 * 2

  return (
    /**
     * We used Wrapper for adding styled props to Image because it's not supported to add it directly
     * to Next/Image component yet and it will not work.
     * We can remove StyledWrapper after Next/Image support in future.
     * */
    <StyledWrapper className={className} thumbnailSize={thumbnailSize}>
      <StyledThumbnail
        src={imageSrc}
        width={imageSize}
        height={imageSize}
        alt={alt ?? t('thumbnail')}
        layout="fixed"
      />
    </StyledWrapper>
  )
}

export default Thumbnail

const StyledWrapper = styled.div<ThumbnailProps>(
  ({ theme, thumbnailSize }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: thumbnailSize,
    height: thumbnailSize,
    background: theme.colors.background.secondary,
    borderRadius: theme.radii[2]
  })
)

const StyledThumbnail = styled(Image)<ThumbnailProps>(({ theme, src }) => ({
  backgroundPosition: 'center',
  borderRadius: theme.radii[2],
  background: src?.includes(DEFAULT_FALLBACK_IMAGE_NAME)
    ? `linear-gradient(20deg, #F4873D -120%, #F4873D -120%, #EC165A -12%, ${theme.colors.primary} 95%, #22A9E1 200%)`
    : undefined,
  objectFit: 'cover'
}))
