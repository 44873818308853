import React, { ReactNode } from 'react'
import styled from '@emotion/styled'

interface ItemTextCtaProps {
  children?: ReactNode
}

/**
 * Use this component with card elements to render a CTA. It is mostly used with an Icon element.
 */
const ItemTextCta = ({ children }: ItemTextCtaProps) => {
  return <StyledItemTextCta>{children}</StyledItemTextCta>
}

export default ItemTextCta

const StyledItemTextCta = styled.div<ItemTextCtaProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `0 ${theme.space[4]}px ${theme.space[4]}px ${theme.space[4]}px`,
  'a:hover &': {
    'svg use': {
      color: theme.colors.icon.darkGrey
    }
  }
}))
