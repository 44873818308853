import styled from '@emotion/styled'
import { defaultTheme } from 'assets/theme/theme'
import React, { ElementType, forwardRef, ReactNode } from 'react'
import {
  space,
  flexbox,
  maxWidth,
  minWidth,
  width,
  height,
  SpaceProps,
  FlexboxProps,
  WidthProps,
  HeightProps,
  MinWidthProps,
  MaxWidthProps,
  system,
  RequiredTheme,
  ResponsiveValue,
  Theme,
  ThemeValue
} from 'styled-system'

interface GapProps<
  ThemeType extends Theme = RequiredTheme,
  TVal = ThemeValue<'space', ThemeType>
> {
  gap?: ResponsiveValue<TVal, ThemeType>
  rowGap?: ResponsiveValue<TVal, ThemeType>
  columnGap?: ResponsiveValue<TVal, ThemeType>
}

export interface FlexProps
  extends SpaceProps,
    FlexboxProps,
    WidthProps,
    HeightProps,
    MinWidthProps,
    MaxWidthProps,
    GapProps {
  /**
   * Takes a string to output a class. Related to emotion - It lets another component style it
   */
  className?: string
  /**
   * Allows the button be rendered as another ElementType, e.g. "span", "div", etc.
   */
  as?: ElementType
  children?: ReactNode
  onClick?: () => void
}

/**
 * The Flex component aims to provide an efficient way to layout, align and distribute space among items in a container.
 * It uses the styled-system to take all the props for flexbox, space, width, minWidth and maxWidth.
 * Pass the rest of the props "...other" to the element that should be styled with styled-system.
 * */

const Flex: React.FC<FlexProps> = forwardRef<HTMLDivElement, FlexProps>(
  ({ children, ...other }, ref) => {
    return (
      <StyledFlex {...other} ref={ref}>
        {children}
      </StyledFlex>
    )
  }
)
Flex.displayName = 'Flex'

export default Flex

const StyledFlex = styled.div(
  {
    display: 'flex'
  },
  space,
  flexbox,
  width,
  height,
  minWidth,
  maxWidth,
  /**
   * Support for gap because this not provided by styled-system.
   */
  system({
    gap: { property: 'gap', scale: 'space', defaultScale: defaultTheme.space },
    rowGap: {
      property: 'rowGap',
      scale: 'space',
      defaultScale: defaultTheme.space
    },
    columnGap: {
      property: 'columnGap',
      scale: 'space',
      defaultScale: defaultTheme.space
    }
  })
)
