import React, { ReactNode } from 'react'
import styled from '@emotion/styled'

interface ItemTextTopProps {
  children: ReactNode
  /**
   * String - review type
   */
  actionText?: string
  /**
   * Actions - React component to be used for rendering buttons / links etx.
   */
  Actions?: React.ComponentType
}

const ItemTextTop = ({ children, Actions }: ItemTextTopProps) => {
  return (
    <StyledItemTextTop>
      {children}
      {Actions && (
        <ActionsContainer>
          <Actions />
        </ActionsContainer>
      )}
    </StyledItemTextTop>
  )
}

export default ItemTextTop

const StyledItemTextTop = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  width: '100%',
  marginBottom: theme.space[1],
  color: theme.colors.text.secondary,
  '> *': {
    /**
     * It combines 4px from them main element with 12px from each child to achieve 16px.
     * It is necessary from creating 12px between children if (flexWrap: 'wrap') moves
     * the children underneath each other.
     */
    marginBottom: theme.space[3],
    /**
     * Add margin-right to the first child if there are more than one children.
     * It is necessary from creating space between the children if (flexWrap: 'wrap') moves
     * the children underneath each other.
     *
     * We are using of-type instead of-child because of-child throws the followng erro:
     * - The pseudo class ":nth-child" is potentially unsafe when doing server-side rendering. Try changing it to ":nth-of-type".
     * We need to assure all children are the same type of html element to make sure it works properly.
     */
    ':not(:only-child):first-of-type': {
      marginRight: theme.space[3]
    }
  }
}))

const ActionsContainer = styled.div(({ theme }) => ({
  paddingBottom: theme.space[1], // fix vertical alignemnt
  a: {
    color: 'currentcolor'
  }
}))
