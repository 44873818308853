import React from 'react'
import styled from '@emotion/styled'
import Text from 'library/atoms/Typography/Text'
import Icon, { IconNames } from 'shared/library/atoms/Icon'

interface ItemBreakdownLineProps {
  /**
   * Loads an icon placeholer if there is a string. This temporary until we implement the Icon component.
   */
  icon?: IconNames
  /**
   * Takes string. Outputs the text.
   */
  text: string

  /**
   * Component to pass in to render the right content for flexibility
   */
  RightContent?: React.ComponentType
}

const ItemBreakdownLine = ({
  icon,
  text,
  RightContent
}: ItemBreakdownLineProps) => {
  return (
    <StyledItemBreakdownLine>
      {icon && <Icon name={icon} />}
      {text && <StyledText font="heading">{text}</StyledText>}
      {RightContent && (
        <StyledRightContenContainer>
          <RightContent />
        </StyledRightContenContainer>
      )}
    </StyledItemBreakdownLine>
  )
}

export default ItemBreakdownLine

const StyledItemBreakdownLine = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginTop: theme.space[4]
}))

const StyledText = styled(Text)(({ theme }) => ({
  marginRight: 'auto',
  marginLeft: theme.space[2]
}))

const StyledRightContenContainer = styled.div({
  marginTop: 0,
  marginRight: 0,
  marginBottom: 0
})
