import React, { ElementType, forwardRef, ReactNode } from 'react'
import styled from '@emotion/styled'
import { space, SpaceProps } from 'styled-system'
import CoverImage from 'library/atoms/CoverImage'
import Icon from 'shared/library/atoms/Icon'

interface ItemUlProps {
  variant?: 'default' | 'grid'
  noGap?: boolean
}

interface ItemProps extends SpaceProps {
  /**
   * Takes a string to output a class. Related to emotion - It lets another component style it
   */
  className?: string

  children?: ReactNode

  /**
   * For layout variations.
   */
  variant?: 'default' | 'card' | 'slimCard'

  border?: boolean

  /**
   * Allow the button be rendered as another ElementType, e.g. "span", "div", etc.
   */
  as?: ElementType

  /**
   * Allows more props. corresponds to ...other
   */
  [propName: string]: unknown
}

type StyledItemProps = Pick<ItemProps, 'variant' | 'border'>

/**
 * If it is a child of Link the Item has to be wrapped in React.forwardRef to receive the passHref from Link
 */
const Item = forwardRef<HTMLLIElement, ItemProps>(
  (
    { children, variant, border, as, ...other } = { variant: 'default' },
    ref
  ) => {
    return (
      <StyledItem
        variant={variant}
        ref={ref}
        border={border}
        /**
         * By default, the Item will output as `li` if the variant is default or `a` if is a card.
         * It can be overwritten if the component has the prop `as` in case the Item has to output as a button or a simple div.
         */
        as={as ?? (variant === 'card' ? 'a' : 'li')}
        {...other}
      >
        {children}
      </StyledItem>
    )
  }
)
Item.displayName = 'Item'

export default Item

export const ItemUl = styled.ul<ItemUlProps>(
  ({ theme, noGap }) => ({
    gap: noGap ? 0 : theme.space[4],
    padding: 0,
    marginTop: 0,
    listStyleType: 'none'
  }),
  ({ variant }) => {
    // Made this a power of 2 but it can really be anything
    const minWidth = 256
    switch (variant) {
      case 'grid':
        return {
          display: 'grid',
          // Fits as many columns as possible respecting the min width BUT ALSO
          // if there's only 1 card it gets to be be full width! Excellent
          gridTemplateColumns: `repeat(auto-fit, minmax(${minWidth}px, 1fr))`,

          // --- Voucher bar mode ---
          // the rest of theses styles only apply when there is a voucher button

          // Selects the voucher bar
          'button:only-of-type': {
            // Put voucher bar in row 2 no matter how many cards are in row 1
            gridRow: 2,
            // Start at the start of the grid and end at the end (-1)
            gridColumnStart: 1,
            gridColumnEnd: -1
          },
          // Sadly setting the above makes the grid always have 3 columns on
          // Unfortunately making the voucher bar span all columns forces the
          // grid to have the max number of columns (3 on desktop) but if there
          // are fewer cards than columns (1 or 2) it looks weird.
          // But we can fix these special cases for 1 or 2 cards.

          // The below CSS requires newer browsers but degrades gracefully.

          // When the container has 3 items (2 cards + 1 voucher button)
          // make the grid max 2 columns (50%) subtracting an arbitrary amount
          // to account for the gap (just has to be > 33.33% and < 50%)
          '&:has(> button:first-child:nth-last-child(3))': {
            gridTemplateColumns: `repeat(auto-fit, minmax(max(${minWidth}px, 40%), 1fr))`
          },
          // When the container has 2 items (1 card + voucher button)
          // make the grid max 1 column (100%) subtracting an arbitrary amount
          // to account for the gap (just has to be > 50% and < 100%)
          '&:has(> button:first-child:nth-last-child(2))': {
            gridTemplateColumns: `repeat(auto-fit, minmax(max(${minWidth}px, 80%), 1fr))`
          }
        }
      default:
        return {
          display: 'flex',
          flexWrap: 'wrap'
        }
    }
  }
)

const StyledItem = styled.li<StyledItemProps>(
  // Default variant
  ({ theme, border }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: theme.radii[3],
    border: border ? theme.border : 0,
    boxShadow: theme.shadows.primary[0],
    background: theme.colors.white,
    transition: `all ${theme.transitionTime[1]}s ${theme.transitionTimingFunction.smooth}`,
    padding: theme.mixins.pxSpread([theme.space[5], theme.space[4]]),
    // change style if declared as "a" tag
    'a&': {
      textDecoration: 'none',
      color: 'inherit'
    }
  }),

  ({ theme, variant }) => {
    switch (variant) {
      case 'card':
        return {
          padding: 0,
          border: theme.border,
          overflow: 'hidden', // Clip CoverImage corners
          cursor: 'pointer',
          '&:hover': {
            transform: `translateY(-${theme.space[1]}px)`,
            boxShadow: theme.shadows.primary[1]
          },
          /**
           * The variant "card" by default outputs as an anchor tag but there are cases
           * the card also outputs as a div.
           */
          '&:not(a)': {
            cursor: 'auto'
          }
        }
      case 'slimCard':
        return {
          alignItems: 'center',
          flexDirection: 'row',
          boxShadow: 'none',
          borderRadius: 0,
          background: 'none',
          cursor: 'pointer',
          borderTop: theme.border,
          h5: {
            position: 'relative',
            left: 0,
            transition: `left ease 0.5s`
          },
          [`${Icon}`]: {
            display: 'none',
            marginLeft: 'auto',
            right: 0
          },
          [`${CoverImage}`]: {
            height: 88,
            width: 84,
            marginBottom: 0,
            marginRight: theme.space[1]
          },
          '&:first-of-type': {
            borderTop: 0
          },
          '&:hover': {
            [`${Icon}`]: {
              display: 'block',
              animation: 'fadeIn 2s',
              '@keyframes fadeIn': {
                '0%': { opacity: 0 },
                '100%': { opacity: 1 }
              }
            },
            h5: {
              left: '4px'
            }
          }
        }
    }
  },

  space
)
