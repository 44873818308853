import { layout, LayoutProps, space, SpaceProps, compose } from 'styled-system'
import styled, { CSSObject } from '@emotion/styled'
import { rgba } from 'polished'
import { DefaultTheme } from 'assets/theme/theme'
import { ReactNode } from 'react'
import Flex, { FlexProps } from 'shared/library/atoms/Flex'

interface SkeletonLoaderProps extends FlexProps {
  children: ReactNode
}

const SkeletonLoader = ({ children, ...other }: SkeletonLoaderProps) => (
  <Flex {...other} aria-busy="true">
    {children}
  </Flex>
)

export default SkeletonLoader
export {
  StyledDivRectangle as SkeletonRectangle,
  StyledDivCircle as SkeletonCircle
}

const pulseBackgroundAnimation = (theme: DefaultTheme): CSSObject => {
  return {
    animation: `fade 1s infinite linear`,
    '@keyframes fade': {
      '0%': {
        background: rgba(theme.colors.background.primary, 0.3)
      },
      '50%': {
        background: rgba(theme.colors.background.primary, 1)
      },
      '100%': {
        background: rgba(theme.colors.background.primary, 0.3)
      }
    }
  }
}

/**
 * Right now we only have support for Circles and Rectangles, but we could
 * add anything more custom. If there is something more custom, we could even
 * export it from the component itself e.g. Qualifications
 */
const StyledDivRectangle = styled.div<LayoutProps & SpaceProps>(
  ({ theme }) => ({
    height: 40,
    width: 120,
    backgroundColor: theme.colors.background.primary,
    ...pulseBackgroundAnimation(theme)
  }),
  compose(layout, space)
)

const StyledDivCircle = styled.div<LayoutProps & SpaceProps>(
  ({ theme }) => ({
    height: 100,
    width: 100,
    borderRadius: '50%',
    backgroundColor: theme.colors.background.primary,
    ...pulseBackgroundAnimation(theme)
  }),
  compose(layout, space)
)
